<template>
	<div :style="`width: ${width}px; height: ${height}px;`" class="d-flex justify-content-center">
		<!-- euro -->
		<svg
			v-if="currentSymbol === '€'"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enable-background="new 0 0 1000 1000"
			xml:space="preserve"
		>
			<g :fill="color" :fill-opacity="opacity">
				<path
					d="M851.5,941.3l-24.4-110.7c-1.4-6.5-4.9-11.4-10.4-14.6c-6-3.3-12.1-3.7-18.1-1.4l-3.5,1.4l-8.4,2.1c-3.7,0.9-8,2-12.9,3.1c-4.9,1.2-10.5,2.3-17.1,3.5c-6.5,1.2-13.3,2.2-20.5,3.1c-7.2,0.9-15,1.7-23.3,2.4c-8.4,0.7-16.7,1-25.1,1c-58.9,0-111.9-15.9-159-47.7c-47.1-31.8-81.8-74.8-104.1-129.1h269.4c5.1,0,9.8-1.7,13.9-5.2c4.2-3.5,6.7-7.8,7.7-12.9l16.7-78c1.4-6.9,0-13.2-4.2-18.8c-4.6-5.6-10.4-8.4-17.4-8.4H391.4c-1.4-31.6-1.4-55.9,0-73.1h339.7c12.5,0,20-6,22.3-18.1l16.7-79.3c1.4-6.5-0.2-12.5-4.9-18.1c-4.2-5.6-10-8.4-17.4-8.4H422c23.2-52,58-92.8,104.4-122.5c46.4-29.7,98.8-44.6,157.3-44.6c1.9-0.9,8.7-0.7,20.5,0.7c11.8,1.4,18.6,2,20.2,1.7c1.6-0.2,7.7,0.5,18.1,2.1c10.4,1.6,15.7,2.4,15.7,2.4l12.2,2.4c3.5,0.7,6.2,1.3,8,1.7l2.8,0.7c6,1.9,11.6,1.3,16.7-1.7c5.1-3,8.4-7.5,9.8-13.6l29.9-110.7c1.4-6,0.7-11.6-2.1-16.7c-4.2-5.6-8.8-9.1-13.9-10.4c-42.7-10.7-87.7-16-135-16c-103.9,0-196.9,29.7-278.8,89.1c-81.9,59.4-138.4,137.8-169.5,235.3h-68.2c-6.5,0-11.8,2.1-16,6.3c-4.2,4.2-6.3,9.5-6.3,16V436c0,6.5,2.1,11.8,6.3,16c4.2,4.2,9.5,6.3,16,6.3h46.6c-1.4,22.3-1.6,46.6-0.7,73.1h-45.9c-6,0-11.3,2.2-15.7,6.6c-4.4,4.4-6.6,9.6-6.6,15.7v78.7c0,6,2.2,11.2,6.6,15.7c4.4,4.4,9.6,6.6,15.7,6.6h66.1c29.2,102.6,84.5,184.1,165.7,244.7C483.1,959.7,578,990,686.5,990c8.8,0,17.7-0.2,26.8-0.7c9.1-0.5,17.5-1.2,25.4-2.1c7.9-0.9,15.7-2,23.3-3.1c7.7-1.2,14.6-2.3,20.9-3.5c6.3-1.2,12.2-2.3,17.8-3.5c5.6-1.2,10.6-2.4,15-3.8c4.4-1.4,8.1-2.4,11.1-3.1c3-0.7,5.5-1.5,7.3-2.4l3.5-0.7c5.1-1.9,9-5.2,11.8-10.1C852.2,952.1,852.9,946.9,851.5,941.3z"
				/>
			</g>
		</svg>
		<!-- dollar -->
		<svg
			v-if="currentSymbol === '$'"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enable-background="new 0 0 1000 1000"
			xml:space="preserve"
		>
			<g :fill="color" :fill-opacity="opacity">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M4235.4,4688.9v-329.2l-187.6-34.4c-403.9-72.7-847.9-260.3-1138.9-480.4C2365.4,3435.2,2061,2748,2097.4,2005.4c26.8-553.2,227.8-978.1,660.4-1406.8c336.9-331.1,769.5-602.9,1282.4-805.8l185.7-72.8l5.7-1196.3c3.8-972.3,0-1198.2-21-1198.2c-59.3,0-357.9,208.6-491.9,344.5c-241.2,245-367.5,518.7-400,863.2c-17.2,193.3-57.4,273.7-158.9,325.4c-65.1,32.5-118.7,36.4-608.7,36.4c-625.9,0-650.8-5.7-727.3-164.6c-40.2-80.4-42.1-107.2-32.5-292.9c74.7-1257.5,897.7-2164.8,2258.6-2492.1l185.7-44v-340.7V-4780H5001h765.6l3.8,329.2l5.8,329.2l181.8,32.5c493.8,88.1,897.7,241.2,1253.7,474.7c166.5,109.1,440.2,369.4,564.6,537.8C8182.2-2531,8320-1683,8128.6-934.6C8069.3-705,7910.4-373.8,7763-174.8c-373.2,499.6-1031.7,913-1847.1,1159.9l-149.3,44v943.6v943.6l82.3-44c44-24.9,141.6-103.4,216.3-178c199.1-195.2,300.5-411.5,325.4-692.9c15.3-164.6,68.9-269.9,160.8-315.8c59.3-30.6,130.1-34.5,599.1-34.5c491.9,0,537.9,3.8,604.9,38.3c93.8,47.9,155,164.6,155,298.6c0,149.3-51.7,430.7-116.7,635.5c-279.5,872.8-966.6,1479.6-1916,1690.1l-111,23v342.6V5020H5001h-765.6V4688.9z M4235.4,2233.1v-738.8l-90,53.6c-248.8,143.6-379,262.2-461.3,419.2c-42.1,80.4-51.7,120.6-51.7,250.7c0,137.8,5.7,166.5,70.8,296.7c84.2,170.4,208.6,298.6,379,390.5c68.9,36.4,130.2,67,139.7,67C4229.7,2972,4235.4,2638.9,4235.4,2233.1z M6025.1-838.9c442.1-216.3,660.4-476.6,660.4-790.5c-1.9-335-168.4-664.2-447.9-882.4c-105.3-80.4-323.5-197.1-426.8-229.7l-44-11.5v1014.5c0,958.9,1.9,1014.5,34.4,1003C5818.3-741.3,5919.8-787.3,6025.1-838.9z"
					/>
				</g>
			</g>
		</svg>
		<!-- chf -->
		<svg
			v-if="currentSymbol === 'CHF'"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enable-background="new 0 0 1000 1000"
			xml:space="preserve"
		>
			<g :fill="color" :fill-opacity="opacity">
				<g transform="translate(0.000000,234.000000) scale(0.100000,-0.100000)">
					<path
						d="M2738.5-1277.9v-3518h-963.2H812v-439.7v-439.7h963.3h963.2v-942.3V-7560h649.1h649.1v942.3v942.3l1394.6,8.4l1390.4,12.5v418.8v418.8l-1390.4,12.6l-1394.6,8.4v858.5v858.6h2261.5h2261.5v544.4v544.4l-2249,8.4L4057.7-1969l-12.6,1562.1l-8.4,1558h2575.7H9188v544.4V2240H5963.3H2738.5V-1277.9z"
					/>
				</g>
			</g>
		</svg>
		<!-- ils -->
		<svg
			v-if="currentSymbol === 'NIS'"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enable-background="new 0 0 1000 1000"
			xml:space="preserve"
		>
			<g :fill="color" :fill-opacity="opacity">
				<path
					d="M711.6,355.2v345.2c0,6.5-2.1,11.8-6.3,16c-4.2,4.2-9.5,6.3-16,6.3H578c-6.5,0-11.8-2.1-16-6.3c-4.2-4.2-6.3-9.5-6.3-16V355.2c0-52-18.6-96.5-55.7-133.6c-37.1-37.1-81.7-55.7-133.6-55.7H177v801.8c0,6.5-2.1,11.8-6.3,16c-4.2,4.2-9.5,6.3-16,6.3H43.4c-6.5,0-11.8-2.1-16-6.3c-4.2-4.2-6.3-9.5-6.3-16V32.3c0-6.5,2.1-11.8,6.3-16c4.2-4.2,9.5-6.3,16-6.3h323c62.6,0,120.4,15.4,173.3,46.3c52.9,30.9,94.8,72.7,125.6,125.6S711.6,292.6,711.6,355.2L711.6,355.2z M978.9,32.3v612.5c0,62.6-15.4,120.4-46.3,173.3c-30.9,52.9-72.7,94.8-125.6,125.6C754,974.6,696.3,990,633.6,990h-323c-6.5,0-11.8-2.1-16-6.3c-4.2-4.2-6.3-9.5-6.3-16V299.5c0-6.5,2.1-11.8,6.3-16s9.5-6.3,16-6.3H422c6.5,0,11.8,2.1,16,6.3s6.3,9.5,6.3,16v534.5h189.3c52,0,96.5-18.6,133.6-55.7c37.1-37.1,55.7-81.7,55.7-133.6V32.3c0-6.5,2.1-11.8,6.3-16c4.2-4.2,9.5-6.3,16-6.3h111.4c6.5,0,11.8,2.1,16,6.3C976.8,20.4,978.9,25.8,978.9,32.3L978.9,32.3z"
				/>
			</g>
		</svg>
		<!-- sterling -->
		<svg
			v-if="currentSymbol === '£'"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enable-background="new 0 0 1000 1000"
			xml:space="preserve"
		>
			<g :fill="color" :fill-opacity="opacity">
				<g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
					<path
						d="M5182.7,4961.6c-1065.5-166-1756.5-714.2-2100.1-1660c-181.5-501.9-239.4-891.8-262.5-1710.2l-23.1-741.2l-444-11.6c-436.2-11.6-444-11.6-525-119.7c-73.3-100.4-81.1-162.1-81.1-606.1c0-444,7.7-509.6,81.1-606.1c81.1-108.1,88.8-108.1,532.7-119.7l447.8-11.6v-1328v-1331.9h-440.1c-494.2,0-532.8-15.4-602.2-262.5c-50.2-185.3-50.2-756.6,3.9-945.8c23.2-81.1,73.3-181.4,115.8-223.9c69.5-69.5,158.3-73.4,3107.7-73.4c1864.6,0,3057.5,15.5,3100,38.6c131.2,69.5,177.6,266.4,177.6,760.5c0,416.9-7.8,482.6-81.1,579.1l-81.1,108.1l-1687,11.6l-1683.2,7.7v1331.9v1331.9h1061.6H6862l77.2,123.5c96.5,162.1,135.1,563.6,88.7,911.1c-65.6,455.6,3.9,432.4-1239.2,432.4H4727.1l23.1,687.2c11.6,374.5,46.3,779.8,77.2,899.5c158.3,637,536.6,976.7,1146.6,1026.9c490.3,42.5,949.7-108.1,1463.1-471c139-96.5,289.5-173.7,335.8-173.7c216.2,0,301.2,270.2,277.9,872.5c-11.5,355.2-30.8,447.8-100.4,563.6C7603.2,4811.1,6325.4,5139.2,5182.7,4961.6z"
					/>
				</g>
			</g>
		</svg>
		<!-- kr -->
		<svg
			v-if="currentSymbol === 'Kr'"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enable-background="new 0 0 1000 1000"
			xml:space="preserve"
		>
			<g :fill="color" :fill-opacity="opacity">
				<g transform="translate(0.000000,282.000000) scale(0.100000,-0.100000)">
					<path
						d="M677.5-2180v-4900H1150h472.5v1449v1449l423.5,521.5c231,283.5,434,521.5,444.5,525c14,7,458.5-875,990.5-1960l966-1967l602-10.5l598.5-7l-1211,2425.5L3222-2229L4324.5-881.5C4930-143,5434,483.5,5451.5,508c17.5,31.5-115.5,42-598.5,42h-623L2935-1105.5L1640-2761L1629.5-20.5l-7,2740.5H1150H677.5V-2180z"
					/>
					<path
						d="M8825.5,676c-448-98-826-378-1127-826c-143.5-210-224-367.5-434-840c-28-66.5-38.5,101.5-38.5,728l-3.5,815.5L6778,543l-448-10.5L6319.5-3272l-7-3808h469h469l17.5,2058c14,1543.5,31.5,2131.5,70,2369.5c206.5,1288,707,1890,1690.5,2023l294,38.5V67v658l-147-3.5C9091.5,718,8934,697,8825.5,676z"
					/>
				</g>
			</g>
		</svg>
	</div>
</template>
<script>
import { store } from '@/store';

export default {
	name: 'CurrencySvg',
	props: {
		color: {
			type: String,
			required: false,
			default: '#000000' //#06263E
		},
		opacity: {
			type: [Number, String],
			required: false,
			default: 1
		},
		width: {
			type: [Number, String],
			required: false,
			default: 16
		},
		height: {
			type: [Number, String],
			required: false,
			default: 16
		}
	},
	data() {
		const { currencySymbol } = store.state.myProfile;
		return {
			currentSymbol: currencySymbol
		};
	}
};
</script>
